<template>
    <div>
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <el-tabs id="table" v-model="activeName" class="all-tabs" @tab-click="getList">
                    <el-tab-pane label="全部消息" name="1"/>
                    <el-tab-pane label="服务提醒" name="2"/>
                    <el-tab-pane label="体温异常" name="3"/>
                </el-tabs>
                <div class="message-content">
                    <table-data :config="table_config" :tableData="table_data" @handleSelectionChange="handleSelectionChange">
                    </table-data>
                    <Pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="getList"
                    />
                </div>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    TableData,
    Pagination,
    // 功能组件
    Loading,
    Error,
    DialogWrapper, debounce,
} from 'common-local';
// 逻辑运算
import {
    mapState
} from 'vuex';
import {CampusOAModel} from "@/models/CampusOA";

export default {
    components: {
        TableData,
        Pagination,
        DialogWrapper,
        Loading,
        Error,
    },
    data() {
        return {
            activeName: '1',
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "标题",
                        prop: "title"
                    },
                    {
                        label: "接受时间",
                        prop: "time",
                    },
                    {
                        label: "消息类型",
                        prop: "type",
                    },
                    {
                        label: "消息状态",
                        prop: "isRead",
                        type: 'function',
                        callBack(row) {
                            if (row.isRead === '1') {
                                return `<div style="color:#59D19F">已读</div>`
                            } else {
                                return `<div style="color: #FAC218">未读</div>`
                            }
                        }
                    },
                ],
                check: false,
                height: ''
            },
            table_data: [],
            total: 0,
            listQuery: {
                schoolId: '',
                pageNum: 1,
                pageRow: 20,
            }
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        })
    },
    created() {
        this.listQuery.schoolId = this.schoolId
        this.init();
    },
    mounted() {
        setTimeout(() => {
            this.table_config.height = document.body.clientHeight - document.getElementById('table').offsetTop - 152
            window.onresize = debounce(() => {
                if (!document.getElementById('table')) return
                const height = document.body.clientHeight - document.getElementById('table').offsetTop - 152
                this.table_config.height = height.toString()
            }, 200)
        }, 200)
    },
    methods: {
        init() {
            this.getList()
        },
        getList() {
            this.table_data = [
                {title: '测试1', time: '5555555555', type: '服务提醒', isRead: '1'},
                {title: '测试2', time: '5555555555', type: '服务提醒', isRead: '0'},
                {title: '测试3', time: '5555555555', type: '体温异常', isRead: '0'},
                {title: '测试4', time: '5555555555', type: '服务提醒', isRead: '1'},
                {title: '测试5', time: '5555555555', type: '体温异常', isRead: '0'},
            ]
        },
        handleSelectionChange(data) {
            console.log(data)
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.center-main {
    height: calc(100vh - 130px);
    margin: 10px;
    padding: 0;
    border-radius: 6px;
    background-color: #fff;
}

.message-content {
    background-color: #fff;
    height: calc(100vh - 180px);
    margin: 10px;
    border-radius: 6px;
    overflow: auto;
}

.all-tabs /deep/ .el-tabs__nav-wrap > * {
    height: 54px;
    line-height: 54px;
}

.el-tabs /deep/ .el-tabs__nav-scroll {
    padding: 0 24px;
}

.all-tabs /deep/ .el-tabs__nav-wrap::after {
    height: 0;
}

.el-tabs /deep/ .el-tabs__header {
    background-color: #ffffff;
    margin: 0;
}

.el-tabs /deep/ .el-tabs__item {
    color: #737373;
    min-width: 76px;
    text-align: center;
}

</style>
